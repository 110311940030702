import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyBreadcrumb from "../components/MyBreadcrumb";
import { Row, Col, Button, Modal } from "react-bootstrap";
import * as Showdown from "showdown";
import { useAuth } from '../auth';
import Spinner from "../components/Spinner";
import useDocumentTitle from "../utils/useDocumentTitle";

const showdownHighlight = require("showdown-highlight");
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  extensions: [showdownHighlight({
    pre: true
  })]
});

export default function SinglePost() {

  const [logged] = useAuth();

  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState({title: ''});
  const [showModal, setShowModal] = useState(false);

  let { postId } = useParams();

  useDocumentTitle(post.title);


  useEffect(() => {
    setLoading(true);
    fetch(`/api/blog/${postId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setPost(data);
        setLoading(false);
      })
      .catch(error => console.error(error));
  }, [postId])

  const handleDeletePost = () => {
    fetch(`/api/blog/${postId}/delete`, {
      method: 'POST'
    })
      .then(response => response.json())
      .then(d => {
        if (d.status === 200) {
          // TODO: add an alert on the blog page
          window.location.pathname = "/blog";
        } else {
          // setShowFailAlert(true)
          // setFailMessage(d.message)
        }
      })
  }

  return (
    !loading
      ?
      <>
        <MyBreadcrumb name={post.title} link={window.location.pathname} />
        <article className="media content-section" key={post.id}>
          <div className="w-100 p-3">
            <h2>
              {post.title}
            </h2>
            <div className="article-metadata">
              <div className="text-muted">{post.datePosted}</div>
              {logged &&
                <Row className="mt-2 mb-2">
                  <Col md={2}>
                    <a href={`/blog/post/${postId}/update-post`}>
                      <Button variant="primary" className="w-100">update</Button>
                    </a>
                  </Col>
                  <Col md={2}>
                    <Button variant="danger" className="w-100" onClick={() => setShowModal(true)}>delete</Button>
                  </Col>
                </Row>
              }
            </div>
            <p className="article-content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.content) }} />
          </div>
        </article>
        {logged &&
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Delete post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete post "{post.title}"?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
                cancel
              </Button>
              <Button variant="danger" onClick={handleDeletePost}>
                confirm
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </>
      : <Spinner loading={loading}/>
  );
}