import { parsePythonTime } from "./timeUtils";
import { TaskFromPython, Task } from "../interfaces";

export default function parseTask(task: TaskFromPython): Task {

  const isPythonNone = (s: string): null | string => s === "None" ? null : s;
  console.log(isPythonNone(task.project_id))

  return {
    id: Number(task.id),
    name: task.name,
    dueDate: parsePythonTime(task.due_date),
    completed: task.completed === "False" ? false : true,
    completionDate: isPythonNone(task.completion_date) ? parsePythonTime(task.completion_date) : null,
    projectId: isPythonNone(task.project_id) ? Number(task.project_id) : null,
    tags: isPythonNone(task.tags),
    notes: isPythonNone(task.notes),
    parentTaskId: isPythonNone(task.parent_task) ? Number(task.parent_task) : null
  }
}