import React, { useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Project, Task } from "../interfaces";

export default function NewTaskModal(props: {
  projectId: number,
  update: Function,
  setShowAlert: Function,
  setFetchSuccess: Function,
  setMessage: Function,
  projects: Project[],
  setProjectId: Function,
  setShowModal: Function,
  showModal: boolean,
  tasks: Task[]
}) {
  const [name, setName] = useState("");
  const [dueDate, setDueDate] = useState<Date | null>(null);
  // ID of parent task
  const [parentTaskId, setParentTaskId] = useState(0);

  const newTaskNameRef: React.RefObject<HTMLInputElement> = useRef(null);

  function addNewTask() {

    props.setShowModal(false);

    if (name.length > 0 && props.projectId !== 0) {
      // reset input
      setName("");
      setDueDate(null);

      fetch("/api/new-task", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          projectId: props.projectId,
          dueDate: dueDate,
          parentTaskId: parentTaskId
        })
      })
        .then(response => {
          if (!response.ok) {
            console.error(response);
          }

          // this avoids updating before fetch call is done
          setTimeout(
            props.update
            , 100)
        })
    } else {
      if (props.projectId === 0) {
        props.setShowAlert(true);
        props.setFetchSuccess(false);
        props.setMessage("Please choose a project.");
      } else {
        props.setShowAlert(true);
        props.setFetchSuccess(false);
        props.setMessage("Please enter task name.");
      }
    }
  }
  return (
    <>
      <Modal show={props.showModal} onHide={() => props.setShowModal(false)}
        onEntered={() => newTaskNameRef.current && newTaskNameRef.current.focus()} >
        <Modal.Header closeButton>
          <Modal.Title>Add new task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex align-items-end mb-2">
            {/* input name */}
            <Col md={8} xs={12} className="mb-2">
              <Form.Label><small>new task</small></Form.Label>
              <Form.Control
                type="text"
                name="text"
                id="new-todo-input"
                size="sm"
                ref={newTaskNameRef}
                autoComplete="off"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addNewTask();
                  }
                }}
              />
            </Col>
            {/* project */}
            <Col md={4} xs={5} className="mb-2">
              <Form.Label><small>project</small></Form.Label>
              <Form.Select size="sm" value={props.projectId} onChange={(e) => props.setProjectId(Number(e.target.value))}>
                <option value={0}></option>
                {props.projects.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
              </Form.Select>
            </Col>
            {/* parent task */}
            <Col md={4} xs={5} className="mb-2">
              <Form.Label><small>parent task</small></Form.Label>
              <Form.Select size="sm" value={parentTaskId} onChange={(e) => {
                setParentTaskId(Number(e.target.value));
                if (props.projectId === 0) {
                  const task = props.tasks.filter(t => t.id === Number(e.target.value))[0];
                  props.setProjectId(Number(task.projectId));
                }
              }}>
                <option value={0}></option>
                {props.projectId !== 0
                  ? props.tasks
                    .filter(t => t.projectId === Number(props.projectId))
                    .filter(t => t.completed === false)
                    .map(t => <option key={t.id} value={t.id}>{t.name}</option>)
                  : props.tasks
                    .filter(t => t.completed === false)
                    .map(t => <option key={t.id} value={t.id}>{t.name}</option>)
                }
              </Form.Select>
            </Col>
            {/* due date */}
            <Col md={4} xs={4} className="mb-2">
              <Form.Label><small>due date</small></Form.Label>
              <DatePicker
                selected={dueDate}
                onChange={date => setDueDate(date)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => props.setShowModal(false)}>
            cancel
          </Button>
          <Button variant="primary" onClick={addNewTask}>
            create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
