import { useState, useEffect } from "react";

export default function useLookUpMeaning() {

  const [word, setWord] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ChineseMeaning, setChineseMeaning] = useState({});
  const [EnglishMeaning, setEnglishMeaning] = useState({});
  // if Chinese meaning is loaded
  const [ChineseMeaningLoaded, setChineseMeaningLoaded] = useState(false);
  // if English meaning is loaded
  const [EnglishMeaningLoaded, setEnglishMeaningLoaded] = useState(false);

  useEffect(() => {
    if (word.length > 0) {
      setChineseMeaningLoaded(false);
      setEnglishMeaningLoaded(false);
      setIsLoading(true);

      // fetch('https://api.dictionaryapi.dev/api/v2/entries/en/' + word)
      fetch('https://www.dictionaryapi.com/api/v3/references/collegiate/json/' + word + '?key=de7fc617-5608-4011-8454-a1fbe70ff61e')
        .then(response => response.json())
        .then(d => {
          setEnglishMeaning(d);
          setEnglishMeaningLoaded(true);
        }).catch(e => {
          console.log(`Failed to load English meaning: ${e}`);
          setEnglishMeaningLoaded(true);
        });

      fetch('https://dict-mobile.iciba.com/interface/index.php?c=word&m=getsuggest&nums=10&is_need_mean=1&word=' + word)
        .then(response => response.json())
        .then(d => {
          console.log(d)
          setChineseMeaning(d);
          setChineseMeaningLoaded(true);
        }).catch(e => {
          console.log(`Failed to load Chinese meaning: ${e}`);
          setChineseMeaningLoaded(true);
        });
    }
  }, [word])

  useEffect(() => {
    if (ChineseMeaningLoaded && EnglishMeaningLoaded) {
      setIsLoading(false)
    }
  }, [ChineseMeaningLoaded, EnglishMeaningLoaded])


  return [isLoading, ChineseMeaning, EnglishMeaning, ChineseMeaningLoaded, EnglishMeaningLoaded, setWord]
}