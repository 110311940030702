import React, { useEffect, useRef, useState } from "react";
import { ListGroup, Row, Col, Button, Modal, InputGroup, Form } from "react-bootstrap";
import ChineseMeaningBlock from "../components/ChineseMeaningBlock";
import EnglishMeaningBlock from "../components/EnglishMeaningBlock";
import MyBreadcrumb from "../components/MyBreadcrumb";
import Spinner from "../components/Spinner";
import SelectWordList from "../components/SelectWordList";
import useLookUpMeaning from "../utils/useLookUpMeaning";
import { Link } from "react-router-dom";
import AutoDismissAlert from "../components/AutoDismissAlert";
import useDocumentTitle from "../utils/useDocumentTitle";

export default function Vocabulary() {

  useDocumentTitle("Vocabulary");

  // word list (file name)
  const [wordListFile, setWordListFile] = useState('default');
  // word list (name)
  const [wordListName, setWordListName] = useState('Default List');
  // all word lists
  const [lists, setLists] = useState([]);

  // words in word list
  const [words, setWords] = useState([]);
  // initials in word list
  const [inits, setInits] = useState([]);

  // chosen word for showing meanings in modal
  const [word, setWord] = useState('');
  // updated word
  const [updatedWord, setUpdatedWord] = useState('');

  const [fetchSuccess, setFetchSuccess] = useState(false);
  // request message
  const [message, setMessage] = useState('');

  // whether to manage list
  const [manageList, setManageList] = useState(false);

  // look up meaning
  const [loading, ChineseMeaning, EnglishMeaning, ChineseMeaningLoaded, EnglishMeaningLoaded, lookUpMeaning] = useLookUpMeaning();

  // word update button action: update (1) or delete (0);
  const [buttonAction, setButtonAction] = useState(0);

  // show DOM elements
  const [showAlert, setShowAlert] = useState(false);
  const [showWordMeaningModal, setShowWordMeaningModal] = useState(false);
  const [showDeleteListModal, setShowDeleteListModal] = useState(false);
  const [showCreateListModal, setShowCreateListModal] = useState(false);

  // create new list
  const [newListName, setNewListName] = useState('');
  const [newListFileName, setNewListFileName] = useState('');

  // input ref
  const inputRef = useRef(null);

  const fetchWordLists = () => {
    fetch("/api/vocabulary/lists.json")
      .then(response => response.json())
      .then(data => {
        setLists(data);
      })
  }

  useEffect(() => {
    fetch("/api/vocabulary/lists.json")
      .then(response => response.json())
      .then(data => {
        setLists(data);
      })
  }, [])

  const getInitsAndWords = () => {
    fetch('/api/vocabulary/' + wordListFile)
      .then(response => response.json())
      .then(data => {
        setInits(data.inits)
        setWords(data.words)
      })
  }
  useEffect(() => {
    fetch('/api/vocabulary/' + wordListFile)
      .then(response => response.json())
      .then(data => {
        setInits(data.inits)
        setWords(data.words)
      })
  }, [wordListFile])

  // update meaning every time word is updated
  useEffect(() => {
    lookUpMeaning(word)
  }, [word, lookUpMeaning])

  function handleWordUpdate(e) {

    e.preventDefault();

    const originalWord = e.target.id.split('update-')[1];

    // delete word
    if (buttonAction === 0) {
      fetch("/api/vocabulary/delete-single-word", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          word: originalWord,
          fileName: wordListFile
        })
      })
        .then(response => {

          setFetchSuccess(response.ok);

          if (response.ok) {
            getInitsAndWords();
          } else {
            setShowAlert(true);
          }

          return response.json();
        })
        .then(d => {
          setMessage(d.message);
        })
    } else {
      // update word
      fetch("api/vocabulary/update-single-word", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          originalWord: originalWord,
          updatedWord: updatedWord,
          fileName: wordListFile
        })
      })
        .then(response => {

          setFetchSuccess(response.ok);

          if (response.ok) {
            getInitsAndWords();
          } else {
            setShowAlert(true);
          }

          return response.json();
        })
        .then(d => {
          setMessage(d.message);
        })
    }
  }

  function handleDeleteList() {
    fetch("/api/vocabulary/delete-list", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fileName: wordListFile
      })
    })
      .then(response => {
        setFetchSuccess(response.ok);
        setShowAlert(true);
        return response.json();
      })
      .then(d => {
        setMessage(d.message);
      })
      .then(setShowDeleteListModal(false))
      .then(fetchWordLists())
      .then(setWordListFile('default'));
  }

  function handleCreateList() {
    fetch("/api/vocabulary/create-list", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: newListName,
        fileName: newListFileName
      })
    })
      .then(response => {
        setFetchSuccess(response.ok);
        setShowAlert(true);
        return response.json();
      })
      .then(d => {
        setMessage(d.message);
      })
      .then(setShowCreateListModal(false))
      .then(fetchWordLists())
      .then(setWordListFile(newListFileName));
  }

  const ListOfWords =
    <Row className="mt-3 d-flex justify-content-center">
      {inits.map(init => (
        <Col md={12} xs={10} className="gold-border-bottom mb-4" key={init}>
          <h4>{init.toUpperCase()}</h4>
          <Row className="mb-2">
            {words.filter(w => w.charAt(0) === init).map(w =>
              <Col md={4} xs={6} key={w} className="mb-1">
                <button className="word button-like-a" href="#" id={w} onClick={() => {
                  setWord(w);
                  setShowWordMeaningModal(true);
                }
                }>{w}</button>
              </Col>
            )}
          </Row>
        </Col>
      ))}
    </Row>

  const ManageListOfWords =
    <Row className="mt-3 d-flex justify-content-center">
      {inits.map(init => (
        <Col md={12} xs={10} className="gold-border-bottom mb-4" key={init}>
          <h4>{init.toUpperCase()}</h4>
          <Row className="mb-2">
            {words.filter(w => w.charAt(0) === init).map(w =>
              <Col md={4} key={w} className="mb-2">
                <Form className="manage-word" id={"update-" + w} onSubmit={handleWordUpdate}>
                  <InputGroup>
                    <Button type="submit" variant="outline-danger" onClick={() => setButtonAction(0)}>
                      &times;
                    </Button>
                    <Form.Control defaultValue={w} onChange={e => setUpdatedWord(e.target.value)} />
                    <Button type="submit" variant="success" onClick={() => setButtonAction(1)}>
                      &#10003;
                    </Button>
                  </InputGroup>
                </Form>
              </Col>
            )}
          </Row>
        </Col>
      ))}
    </Row>

  return (
    <>
      <MyBreadcrumb link={window.location.pathname} />
      <AutoDismissAlert success={fetchSuccess} show={showAlert} setShow={setShowAlert}>
        {message}
      </AutoDismissAlert>
      <h3 className="fw-medium mb-3 mt-1" id="col-title">Vocabulary</h3>
      <ListGroup>
        <ListGroup.Item>
          <Row>
            <Col md={5} xs={12} className="mt-2 mb-2">
              Word list: &nbsp;
              <SelectWordList list={wordListFile} lists={lists} size="sm" style={{ width: 'auto', display: 'inline-block' }}
                onChange={e => {
                  setWordListFile(e.target.value)
                  setWordListName(e.target.options[e.target.selectedIndex].text)
                }} />
            </Col>
            <Col md={{ offset: 0, span: 2 }} xs={4} className="mt-2 mb-2">
              <Button size="sm" className="w-100" variant="GT"
                onClick={() => setManageList(!manageList)}
              >
                {!manageList
                  ? "manage list"
                  : "view list"
                }
              </Button>
            </Col>
            <Col md={2} xs={4} className="mt-2 mb-2">
              <Button size="sm" className="w-100" variant="danger"
                onClick={() => setShowDeleteListModal(true)}>
                delete list
              </Button>
            </Col>
            <Col md={{ offset: 0, span: 2 }} xs={4} className="mt-2 mb-2">
              <Button size="sm" className="w-100" variant="GT-outline-click"
                onClick={() => setShowCreateListModal(true)}
              >
                new list
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col md={4} xs={12} className="mt-2 mb-2">
              <Link to="/vocabulary/bulk-import-words">
                <Button size="sm" className="w-100" variant="GT-outline-click" >
                  bulk import
                </Button>
              </Link>
            </Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
      {manageList
        ? ManageListOfWords
        : ListOfWords
      }
      {/* show word meaning */}
      <Modal show={showWordMeaningModal} onHide={() => setShowWordMeaningModal(false)} >
        <Modal.Header closeButton>
          <Modal.Title>{word}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EnglishMeaningBlock word={word} meanings={EnglishMeaning} loaded={EnglishMeaningLoaded} />
          <ChineseMeaningBlock word={word} meanings={ChineseMeaning} loaded={ChineseMeaningLoaded} />
          <Spinner loading={loading} />
        </Modal.Body>
      </Modal>
      {/* delete list */}
      <Modal show={showDeleteListModal} onHide={() => setShowDeleteListModal(false)} >
        <Modal.Header closeButton>
          <Modal.Title>Delete word list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete word list <span className="fw-medium">"{wordListName}"</span>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowDeleteListModal(false)}>
            cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteList}>
            delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* create new list */}
      <Modal show={showCreateListModal} onHide={() => setShowCreateListModal(false)}
        onEntered={() => inputRef.current.focus()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create word list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formListFileName">
              <Form.Label>list name</Form.Label>
              <Form.Control ref={inputRef} type="text" onChange={e => setNewListName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formListName">
              <Form.Label>file name</Form.Label>
              <InputGroup>
                <Form.Control type="text" onChange={e => setNewListFileName(e.target.value)} />
                <InputGroup.Text>.txt</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowCreateListModal(false)}>
            cancel
          </Button>
          <Button variant="primary" onClick={handleCreateList}>
            confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}