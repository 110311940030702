import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import MyBreadcrumb from "../components/MyBreadcrumb";
import { Button, Form } from "react-bootstrap";
import "react-mde/lib/styles/css/react-mde-all.css";
import useDocumentTitle from "../utils/useDocumentTitle";

const showdownHighlight = require("showdown-highlight");
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  extensions: [showdownHighlight({
    pre: true
  })]
});

export default function UpdatePost() {

  useDocumentTitle("Update Post");

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");

  let { postId } = useParams();

  useEffect(() => {
    fetch(`/api/blog/${postId}/update`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(d => {
        setTitle(d.title);
        setContent(d.content);
        document.getElementById('title').value = d.title;
      })
  }, [postId])

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`/api/blog/${postId}/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: title,
        content: content
      })
    })
      .then(response => response.json())
      .then(d => {
        if (d.status === 200) {
          // TODO: add an alert on the blog page
          window.location.pathname = "/blog/post/" + postId;
        } else {
          // setShowFailAlert(true)
          // setFailMessage(d.message)
        }
      })
  }

  return (
    <>
      <MyBreadcrumb link={window.location.pathname} names={['blog', title, 'update post']}
        links={['/blog', window.location.pathname.split('/update-post')[0]]}
        />
      <h2>Update post</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" onChange={(e) => setTitle(e.target.value)} />
        </Form.Group>
        <Form.Label>Editor</Form.Label>
        <ReactMde
          value={content}
          onChange={setContent}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          generateMarkdownPreview={markdown =>
            Promise.resolve(converter.makeHtml(markdown))
          }
        />
        <Button type="submit" className="mt-2 mb-2">Update Post</Button>
      </Form>
    </>
  );
}