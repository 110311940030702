import React from "react";

interface response {
  message: ChineseMeaning[],
  status: number
}

interface ChineseMeaning {
  key: string,
  paraphrase: string,
  value: number,
  means: mean[]
}

interface mean {
  part: string,
  means: string[]
}

export default function ChineseMeaningBlock({ loaded, response, word }:
  {
    loaded: boolean,
    response: response,
    word: string
  }) {
  return (
    loaded && response && (
      Object.keys(response).length > 0
        ? response.message.length > 0
          ? response.message.filter((m: ChineseMeaning) => m.key === word).length > 0
            ? response.message.filter((m: ChineseMeaning) => m.key === word)[0].means
              .map((mm: mean) =>
                <p key={mm.means.join(';')}>
                  {mm.part}, {mm.means.join('；')}
                </p>)
            : <p>Chinese meaning not found.</p>
          : <p>Chinese meaning not found.</p>
        : null)
  )
}