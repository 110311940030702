import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Button, Form, ListGroup, ButtonGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isOverdue, isClose, convertDateToString } from "../utils/timeUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-regular-svg-icons"
import { Task, Project } from "../interfaces";

export default function TaskRow({ task, toggleTaskCompleted, deleteTask, editTask, projects, indentLevels, tasks }:
  {
    task: Task,
    toggleTaskCompleted: Function,
    deleteTask: Function,
    editTask: Function,
    projects: Project[],
    indentLevels: any,
    tasks: Task[]
  }) {

  const indentLevel = !indentLevels ? 0 : indentLevels[task.id];

  const dueDateString = task.dueDate && convertDateToString(task.dueDate);

  const [isEditing, setEditing] = useState(false);
  const [newName, setNewName] = useState(task.name);
  const [newDueDate, setNewDueDate] = useState(task.dueDate);
  const [newProjectId, setNewProjectId] = useState(task.projectId);
  const [newParentTaskId, setNewParentTaskId] = useState(task.parentTaskId ? task.parentTaskId : 0);

  const editFieldRef: React.RefObject<HTMLInputElement> = useRef(null);
  const editButtonRef: React.RefObject<HTMLButtonElement> = useRef(null);

  function handleChange(e: React.ChangeEvent) {
    setNewName((e.target as HTMLInputElement).value);
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    editTask(task.id, newName, newDueDate, newProjectId, newParentTaskId);
    setNewName(task.name);
    setNewDueDate(null);
    setEditing(false);
  }

  const editingTemplate = (
    <Row className="d-flex align-items-end">
      {/* input rename */}
      <Col lg={7} xs={12} className="mt-2">
        <Form.Label><small>task name</small></Form.Label>
        <Form.Control size="sm" id={task.id.toString()} type="text"
          value={newName}
          onChange={(e) => handleChange(e)}
          ref={editFieldRef}
        />
      </Col>
      {/* project */}
      <Col md={3} xs={6} className="mt-2">
        <Form.Label><small>project</small></Form.Label>
        <Form.Select size="sm" value={newProjectId ? newProjectId : 0} onChange={(e) => setNewProjectId(Number(e.target.value))}>
          {projects.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
        </Form.Select>
      </Col>
      {/* parent task */}
      <Col md={7} xs={6} className="mt-2">
        <Form.Label><small>parent task</small></Form.Label>
        <Form.Select size="sm" value={newParentTaskId} onChange={(e) => {
          setNewParentTaskId(Number(e.target.value));
          // // 1/25/21: cancel this
          // // update project if parent task is in another project
          // const newParentTask = tasks.filter(t => t.id === Number(e.target.value))[0];
          // setNewProjectId(projects.filter(p => Number(p.id) === newParentTask.projectId)[0].id);
        }}>
          <option value={0}></option>
          {tasks
            .filter(t => t.projectId === newProjectId)
            .map(t => <option key={t.id} value={t.id}>{`\xa0`.repeat(indentLevels[t.id.toString()] * 2) + t.name}</option>)}
        </Form.Select>
      </Col>
      {/* due date */}
      <Col lg={2} xs={6} className="me-auto mt-2">
        <Form.Label><small>due date</small></Form.Label>
        <DatePicker selected={newDueDate} onChange={date => setNewDueDate(date)} />
      </Col>
      {/* desktop button */}
      {/* confirm */}
      <Col lg={1} xs={2} className="d-none d-sm-block">
        <Button size="sm" type="submit" variant="primary" className="w-100" onClick={(e) => handleSubmit(e)} >
          &#10003;
        </Button>
      </Col>
      {/* cancel */}
      <Col lg={1} xs={2} className="d-none d-sm-block">
        <Button size="sm" variant="outline-secondary" className="w-100" onClick={() => setEditing(false)}>
          &times;
        </Button>
      </Col>
      {/* mobile button */}
      <Col xs="auto" className="d-xs-block d-sm-none mt-2">
        <ButtonGroup>
          {/* confirm */}
          <Button size="sm" type="submit" variant="primary" className="w-100" onClick={handleSubmit} >
            &#10003;
          </Button>
          {/* cancel */}
          <Button size="sm" variant="outline-secondary" className="w-100" onClick={() => setEditing(false)}>
            &times;
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
  const viewTemplate = (
    <>
      <Row className="d-flex align-items-center">
        <Col lg={1} xs={1} style={{ textAlign: "center" }}>
          <Form>
            <Form.Check type="checkbox"
              id={task.id.toString()} defaultChecked={task.completed}
              onChange={() => toggleTaskCompleted(task.id)}
            />
          </Form>
        </Col>
        <Col xs="6" style={{ textIndent: 0.75 * indentLevel + "rem" }}>
          {task.completed
            ? <span style={{ color: "gray", textDecorationLine: "line-through" }}>{task.name}</span>
            : <span>{task.name}</span>
          }
        </Col>
        <Col xs="2" className="me-auto">
          {task.completed
            ? <span style={{ color: "gray", textDecorationLine: "line-through" }}>{dueDateString}</span>
            : <span>{dueDateString}</span>
          }
        </Col>
        {/* desktop button */}
        <Col lg={1} className="d-none d-sm-block">
          <Button className="w-100" size="sm" variant="outline-primary" onClick={() => setEditing(true)} ref={editButtonRef}
          // style={{ backgroundColor: "white" }}
          >
            edit
          </Button>
        </Col>
        <Col lg={1} className="d-none d-sm-block">
          <Button className="w-100" size="sm" variant="outline-danger" onClick={() => deleteTask(task.id)}
          // style={{ backgroundColor: "white" }}
          >
            &times;
          </Button>
        </Col>
        {/* mobile button */}
        <Col xs="auto" className="d-xs-block d-sm-none">
          <ButtonGroup>
            <Button size="sm" variant="outline-primary" onClick={() => setEditing(true)} ref={editButtonRef}
            // style={{ backgroundColor: "white" }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button size="sm" variant="outline-danger" onClick={() => deleteTask(task.id)}
            // style={{ backgroundColor: "white" }}
            >
              &times;
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </>
  );

  useEffect(() => {
    if (isEditing && editFieldRef.current) {
      editFieldRef.current.focus();
    } else {
      editButtonRef.current!.focus();
    }
  }, [isEditing]);

  return <ListGroup.Item
    style={!task.completed
      ? task.dueDate && isOverdue(task.dueDate)
        ? {
          backgroundColor: "#ffebee",
          color: "#d32f2f"
        }
        : task.dueDate && isClose(task.dueDate)
          ? {
            backgroundColor: "#fffde7",
            color: "#c49000"
          }
          : {}
      : {}}>
    {isEditing ? editingTemplate : viewTemplate}
  </ListGroup.Item>;
}
