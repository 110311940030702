import React, { useRef, useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import MyBreadcrumb from "../components/MyBreadcrumb";
import { useAuth } from "../auth";
import ChineseMeaningBlock from "../components/ChineseMeaningBlock";
import EnglishMeaningBlock from "../components/EnglishMeaningBlock";
import Spinner from "../components/Spinner";
import useLookUpMeaning from "../utils/useLookUpMeaning";
import AutoDismissAlert from "../components/AutoDismissAlert";
import useDocumentTitle from "../utils/useDocumentTitle";

export default function Dictionary() {

  useDocumentTitle("Dictionary");

  // to disable "add to word list" button when not logged in
  const [logged] = useAuth();
  // word in the searching block
  const [word, setWord] = useState('');
  // word to look up
  const [lookedUpWord, setLookedUpWord] = useState('');
  // word to be added to the list
  const [addedWord, setAddedWord] = useState('');
  // all word lists
  const [lists, setLists] = useState([]);
  // word list (to add word to)
  const [wordListFile, setWordListFile] = useState('default');
  // to show a block
  const [showMeaning, setShowMeaning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  // if fetch is success
  const [fetchSuccess, setFetchSuccess] = useState(false);
  // api call failure message
  const [message, setMessage] = useState('');
  // look up meaning
  const [loading, ChineseMeaning, EnglishMeaning, ChineseMeaningLoaded, EnglishMeaningLoaded, lookUpMeaning] = useLookUpMeaning();
  // input ref
  const inputRef = useRef(null);

  useEffect(() => {
    fetch("/api/vocabulary/lists.json")
      .then(response => {
        return response.json();
      })
      .then(data => {
        setLists(data);
      })
  }, [])

  // focus on input
  useEffect(() => {
    inputRef.current.focus();
  }, [])

  function handleAddWord() {
    fetch("/api/vocabulary/add-single-word", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        word: addedWord,
        fileName: wordListFile
      })
    })
      .then(response => {
        setShowAlert(true);
        setFetchSuccess(response.ok);
        return response.json();
      })
      .then(r => {
        setMessage(r.message);
        setShowModal(false);
      })
  }

  return (
    <>
      <MyBreadcrumb link={window.location.pathname} />
      <AutoDismissAlert success={fetchSuccess} show={showAlert} setShow={setShowAlert}>
        {message}
      </AutoDismissAlert>
      <h3 className="fw-medium mb-3 mt-1" id="col-title">Dictionary</h3>
      <Form onSubmit={e => {
        e.preventDefault();
        if (word.length > 0) {
          setShowMeaning(true);
          setLookedUpWord(word);
          lookUpMeaning(word);
        }
      }}>
        <Row>
          <Col lg={7}>
            <Form.Group className="mb-1">
              <Form.Control type="text" ref={inputRef} placeholder="" onChange={e => setWord(e.target.value.trim())} />
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Button type="submit" className="w-100 mb-1" variant="GT-outline-click">
              look up
            </Button>
          </Col>
          <Col lg={3}>
            <Button type="" className="w-100 mb-1" variant="GT-outline-click" disabled={!logged}
              onClick={
                () => {
                  setAddedWord(word)
                  if (word.length > 0) {
                    setShowModal(true)
                  }
                }
              }>
              add to word list
            </Button>
          </Col>
        </Row>
      </Form>
      {showMeaning
        ? <>
          <h4 className="fw-medium mb-3 mt-3">{lookedUpWord}</h4>
          <EnglishMeaningBlock word={lookedUpWord} meanings={EnglishMeaning} loaded={EnglishMeaningLoaded} />
          <ChineseMeaningBlock word={lookedUpWord} response={ChineseMeaning} loaded={ChineseMeaningLoaded} />
          <Spinner loading={loading} />
        </>
        : null
      }
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add to word list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Choose a word list to add "{addedWord}":
          <Form.Select value={wordListFile} onChange={e => setWordListFile(e.target.value)} >
            {lists.map(list => <option key={list.fileName} value={list.fileName}>{list.name}</option>)}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
            cancel
          </Button>
          <Button variant="primary" onClick={handleAddWord}>
            confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}