import React, { useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import { Project } from "../interfaces";

export default function ProjectButtons({ projects, projectId, setProjectId }: {
  projects: Project[],
  projectId: number,
  setProjectId: Function
}) {

  useEffect(() => {

    const toggleProject = () => {
      document.querySelectorAll('.project-btn').forEach(b => b.classList.value = 'w-100 btn btn-GT-outline project-btn');
      document.querySelector('#project-' + projectId)!.classList.value = 'w-100 btn btn-GT project-btn';
    }

    toggleProject();
  }, [projectId])

  const allProjectsButton: Project = {
    id: 0,
    name: "All"
  }

  const fullProjects = [allProjectsButton].concat(projects);

  return (
    fullProjects.map(p =>
      <Col md={2} xs={6} className="mt-2" key={p.id}>
        <Button variant="GT-outline" id={"project-" + p.id}
          className="w-100 project-btn"
          onClick={() => { setProjectId(p.id) }}>
          {p.name}
        </Button>
      </Col>
    )
  )
}