import React from "react";
import { Breadcrumb } from "react-bootstrap";

interface props {
  link: string,
  links?: string[],
  name?: string,
  names?: string[]
}

const updateLink = (link: string) => {

  if (link.includes('blog/post')) {
    return link.split('post/')[0].concat(link.split('post/')[1]);
  } else {
    if (link.includes('blog/') && link.split('blog/')[1]) {
      return '/blog';
    } else {
      return link;
    }
  }
}

export default function MyBreadcrumb({ link, links, name, names }: props) {

  const modifiedLink = updateLink(link);

  const rest = modifiedLink.split('/').slice(1, -1);

  let last;
  if (name) {
    last = name;
  } else {
    last = modifiedLink.split('/').slice(-1)[0].replaceAll('-', ' ');
  }

  let restDict;
  let restDictLinks: string[];
  if (links) {
    restDictLinks = links;
  } else {
    restDictLinks = rest.map(l => modifiedLink.split(l)[0] + l);
  }
  if (names) {
    restDict = rest.map((_, i) => Object({
      name: names[i],
      link: restDictLinks[i]
    }));
  } else {
    restDict = rest.map((l, i) => Object({
      name: l.replace('-', ' '),
      link: restDictLinks[i]
    }));
  }


  return (
    <Breadcrumb className="mt-3 mt-sm-0">
      <Breadcrumb.Item href="/">home</Breadcrumb.Item>
      {restDict.map(l => <Breadcrumb.Item href={l.link} key={l.link}>{l.name}</Breadcrumb.Item>)}
      <Breadcrumb.Item active>{last}</Breadcrumb.Item>
    </Breadcrumb>
  )
}