import React from "react";
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {
  return (
    <Container className="footer">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <span className="text-muted ms-3">&copy; Pengfei Cheng, 2022</span>
        <ul className="nav justify-content-end list-unstyled d-flex me-3">
          <li className="ms-3">
            <a className="text-muted" href="mailto:me@pengfeicheng.com">
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-muted" href="https://www.linkedin.com/in/pengfei-cheng/">
              <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
            </a>
          </li>
          <li className="ms-3">
            <a className="text-muted" href="https://www.instagram.com/oneofpengfeicheng">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
          </li>
        </ul>
      </footer>
    </Container>
  )
}