import useKeyPress from "../utils/useKeyPress";
import useDocumentTitle from "../utils/useDocumentTitle";

// Usage
export default function Playground() {

  useDocumentTitle("Playground");

  // Call our hook for each key that we'd like to monitor
  const happyPress = useKeyPress("h");
  const sadPress = useKeyPress("s");
  const robotPress = useKeyPress("r");
  const foxPress = useKeyPress("f");
  const ctrlPress = useKeyPress("Control");
  return (
    <div>
      <div>h, s, r, f</div>
      <div>
        {happyPress && ctrlPress && "😊"}
        {sadPress && "😢"}
        {robotPress && "🤖"}
        {foxPress && "🦊"}
      </div>
    </div>
  );
}