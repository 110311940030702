import React, { useEffect, useRef, useState } from "react";
import NewTaskForm from "../components/NewTaskForm";
import TaskRow from "../components/TaskRow";
import MyBreadcrumb from "../components/MyBreadcrumb";
import { Button, Row, Col, Modal, Form, ListGroup, ButtonGroup } from "react-bootstrap";
import ProjectButtons from "../components/ProjectButtons";
import parseTask from "../utils/parseTask";
import { useAuth } from "../auth";
import AutoDismissAlert from "../components/AutoDismissAlert";
import useDocumentTitle from "../utils/useDocumentTitle";
import { isOverdue, isClose } from "../utils/timeUtils";
import useKeyPress from "../utils/useKeyPress";
import NewTaskModal from "../components/NewTaskModal";
import parseTaskTree from "../utils/parseTaskTree";

const FILTER_MAP = {
  active: task => !task.completed,
  upcoming: task => isClose(task.dueDate) && !task.completed && !isOverdue(task.dueDate),
  overdue: task => isOverdue(task.dueDate) && !task.completed,
  completed: task => task.completed
}

const FILTER_NAMES = Object.keys(FILTER_MAP);

function Todo() {

  useDocumentTitle("Todo");

  // monitor key pressing
  const NPress = useKeyPress("N");
  const ctrlPress = useKeyPress("Control");

  const [logged] = useAuth();

  // all tasks
  const [tasks, setTasks] = useState([]);
  // all projects
  const [projects, setProjects] = useState([]);
  // current filter
  const [filter, setFilter] = useState('active');
  // current project ID
  const [projectId, setProjectId] = useState(0);
  // current project name
  const [projectName, setProjectName] = useState('');
  // new project name
  const [newProjectName, setNewProjectName] = useState('');
  // notification
  const [message, setMessage] = useState('');
  // show elements
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  // ref for modal input focus
  const modalInputRef = useRef(null);
  // if fetch succeed
  const [fetchSuccess, setFetchSuccess] = useState(false);

  const indentLevels = useRef({});
  const taskTree = useRef([]);

  useEffect(() => {
    if (NPress && ctrlPress) {
      setShowNewTaskModal(true);
    }
  }, [NPress, ctrlPress]);

  const fetchProjectsAndTasks = () => {
    // console.log("getting all projects and tasks...")
    fetch('/api/tasks', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setTasks(data.tasks.map(t => parseTask(t)));
        setProjects(data.projects);
      })
      .catch(error => console.error(error))
  }
  useEffect(fetchProjectsAndTasks, [])

  useEffect(() => {
    [indentLevels.current, taskTree.current] = parseTaskTree(tasks);
  }, [tasks]);
  

  // get project name
  useEffect(() => {
    projects && projectId !== 0 && projects[0].id &&
      setProjectName(projects.filter(p => Number(p.id) === Number(projectId))[0].name)
  }, [projects, projectId])

  useEffect(() => {
    const toggleFilterButton = () => {

      document.querySelectorAll('.filter-btn').forEach(b => b.classList = 'btn btn-outline-secondary filter-btn');
      const filterButton = document.getElementById('filter-button-' + filter);
      if (filterButton) {
        filterButton.classList = 'btn btn-secondary filter-btn';
      }
    }

    // somehow need to add timeout so that it'll work for the initial rendering 
    setTimeout(toggleFilterButton, 50);
  }, [filter])

  const handleNewProject = () => {
    fetch("/api/new-project", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: newProjectName
      })
    })
      .then(response => {
        setShowAlert(true);
        setFetchSuccess(response.ok);
        return response.json();
      })
      .then(r => {
        setMessage(r.message);
        fetchProjectsAndTasks();
        setShowNewProjectModal(false);
      })
  }

  const handleDeleteProject = (e) => {
    e.preventDefault();
    fetch("/api/delete-project", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: projectId
      })
    })
      .then(response => {
        setShowAlert(true);
        setFetchSuccess(response.ok);
        return response.json();
      })
      .then(r => {
        setMessage(r.message);
        setProjectId(0);
        fetchProjectsAndTasks();
        setShowDeleteProjectModal(false);
      })
  }

  function toggleTaskCompleted(taskId) {

    fetch("/api/complete-task", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: taskId
      })
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response);
        }
      })
      .then(() => {
        setTimeout(fetchProjectsAndTasks, 100);
      })
  }

  function deleteTask(taskId) {

    fetch("/api/delete-task", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: taskId
      })
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response);
        }
      })
      .then(() => {
        setTimeout(fetchProjectsAndTasks, 100);
      })
  }

  function editTask(taskId, newName, newDueDate, newProjectId, newParentTaskId) {

    fetch("/api/edit-task", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: taskId,
        newName: newName,
        newDueDate: newDueDate,
        newProjectId: newProjectId,
        newParentTaskId: newParentTaskId
      })
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response);
        }
      })
      .then(() => {
        setTimeout(fetchProjectsAndTasks, 100);
      })
  }

  const filterBySelectedProjectId = (project) => {
    if (Number(projectId) === 0) {
      return project;
    } else {
      if (Number(projectId) === Number(project.id)) {
        return project;
      } else {
        return null;
      }
    }
  }

  return (
    <>
      <MyBreadcrumb link={window.location.pathname} />
      <AutoDismissAlert success={fetchSuccess} show={showAlert} setShow={setShowAlert}>
        {message}
      </AutoDismissAlert>
      <h3 className="fw-medium mb-3 mt-1">Todo List</h3>
      {logged
        ? <>
          {/* projects */}
          <h4 className="fw-medium mb-2 mt-2">Projects</h4>
          <ListGroup className="mb-4">
            <ListGroup.Item>
              <Row className="mb-2 mt-2">
                <ProjectButtons projects={projects} projectId={projectId} setProjectId={setProjectId} />
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row className="mb-2 mt-2">
                <Col md="3" xs={6}>
                  <Button size="sm" variant="primary" onClick={() => setShowNewProjectModal(true)}
                    className="w-100">
                    add new project
                  </Button>
                </Col>
                <Col md="3" xs={6}>
                  <Button size="sm" variant="danger" onClick={() => setShowDeleteProjectModal(true)}
                    className="w-100">
                    delete project
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
          {/* tasks */}
          <h4 className="fw-medium mb-2 mt-2">Tasks</h4>
          {/* NewTaskForm */}
          <ListGroup className="mb-4 d-sm-none">
            <ListGroup.Item>
              <NewTaskForm projectId={projectId} update={() => setTimeout(fetchProjectsAndTasks, 100)}
                setProjectId={setProjectId} projects={projects}
                setShowAlert={setShowAlert} setMessage={setMessage} setFetchSuccess={setFetchSuccess}
              />
            </ListGroup.Item>
          </ListGroup>
          <Row className="mt-2">
            <Col className="me-auto">
            </Col>
            <Col lg="auto" xs="auto">
              {/* filters */}
              <ButtonGroup size="sm">
                {FILTER_NAMES.map(name => (
                  <Button variant="outline-secondary" key={name} className="filter-button" id={"filter-button-" + name} onClick={
                    () => {
                      setFilter(name);
                    }}>
                    {name}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
          {/* task list */}
          {projects.filter(filterBySelectedProjectId)
            .map(p =>
              <div key={p.id} className="mt-2">
                {/* print projects with more than 1 task after filtering */}
                {projectId === 0
                  ? tasks.filter(FILTER_MAP[filter]).filter(t => t.projectId === Number(p.id)).length > 0
                    ? <h5 key={"project-" + p.id}>{p.name}</h5>
                    : null
                  : <h5>{p.name}</h5>}
                <ListGroup key={"tasks-" + p.id} className="mt-2 mb-3">
                  {tasks
                    .filter(FILTER_MAP[filter])
                    .filter(t => t.projectId === Number(p.id))
                    .sort((a, b) => {
                      return taskTree.current.indexOf(a.id) - taskTree.current.indexOf(b.id);
                    })
                    .map(task => (
                      <TaskRow key={task.id} task={task} projects={projects} 
                        tasks={tasks.filter(t => !t.completed && t.id !== task.id)}
                        toggleTaskCompleted={toggleTaskCompleted} deleteTask={deleteTask} editTask={editTask}
                        indentLevels={indentLevels.current}
                      />
                    ))}
                </ListGroup>
              </div>
            )}
          <Modal
            show={showNewProjectModal} onHide={() => setShowNewProjectModal(false)}
            onEntered={() => modalInputRef.current.focus()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add new project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formListFileName">
                  <Form.Label>Project name</Form.Label>
                  <Form.Control ref={modalInputRef} type="text" onChange={e => setNewProjectName(e.target.value)}
                    // cannot use onKeyUp here
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleNewProject();
                      }
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => setShowNewProjectModal(false)}>
                cancel
              </Button>
              <Button variant="primary" onClick={handleNewProject}>
                confirm
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showDeleteProjectModal} onHide={() => setShowDeleteProjectModal(false)} >
            <Modal.Header closeButton>
              <Modal.Title>Delete project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete project "{projectName}"?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => setShowDeleteProjectModal(false)}>
                cancel
              </Button>
              <Button variant="danger" onClick={handleDeleteProject}>
                delete
              </Button>
            </Modal.Footer>
          </Modal>
          <NewTaskModal projectId={projectId} update={() => setTimeout(fetchProjectsAndTasks, 100)}
            setProjectId={setProjectId} projects={projects}
            setShowAlert={setShowAlert} setMessage={setMessage} setFetchSuccess={setFetchSuccess}
            showModal={showNewTaskModal} setShowModal={setShowNewTaskModal}
            tasks={tasks}
          />
        </>
        :
        <p>This page is only for logged users.</p>
      }
    </>
  );
}

export default Todo;