import { Task } from "../interfaces";

export default function parseTaskTree(tasks: Task[]) {

  let indentLevels:any = {};
  let taskTree:any = {};
  for (const t of tasks) {
    indentLevels[String(t.id)] = 0;
  }

  try {

    let parentTaskIds = tasks.filter(t => !t.parentTaskId).map(t => t.id);
    for (const t of parentTaskIds) {
      taskTree[String(t)] = {};
    }
    let parentTaskIdsCopy;
    let remainTasks = tasks.filter(t => !parentTaskIds.includes(t.id));

    while (remainTasks.length > 0) {

      parentTaskIdsCopy = [...parentTaskIds];

      for (const t of remainTasks) {
        const id = t.id;
        indentLevels[String(id)] += 2;
        if (t.parentTaskId && parentTaskIdsCopy.includes(t.parentTaskId)) {
          parentTaskIds.push(t.id);
          const locatedParent = customFilter(taskTree, String(t.parentTaskId));
          try {
            locatedParent[String(t.parentTaskId)][String(t.id)] = {};
          } catch {}
        }
      }
      remainTasks = tasks.filter(t => !parentTaskIds.includes(t.id));
    }

  } catch (e) {
    console.error(e);
  }

  return [indentLevels, flatten(taskTree)];
}

function customFilter(object: any, key: String): any {
  if(object.hasOwnProperty(key))
      return object;

  for(var i=0; i<Object.keys(object).length; i++){
      if(typeof object[Object.keys(object)[i]] == "object"){
          var o = customFilter(object[Object.keys(object)[i]], key);
          if(o != null)
              return o;
      }
  }

  return null;
}

function flatten(taskTree: any): Number[] {

  if (!taskTree) {
    return [];
  }
  let tasks: Number[] = [];
  // console.log(taskTree)
  for (const [k, v] of Object.entries(taskTree)) {
    tasks.push(Number(k));
    if (v) {
      tasks = tasks.concat(flatten(v));
    }
  }
  return tasks;
}