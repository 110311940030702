import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAtom, faCode, faPencilRuler, faGraduationCap } from '@fortawesome/free-solid-svg-icons'

export default function Home() {

  const sidebarContent = [
    {
      icon: faAtom,
      text: "Optimization",
      id: 0
    },
    {
      icon: faAtom,
      text: "Process Systems Engineering",
      id: 1

    },
    {
      icon: faCode,
      text: "Data Science",
      id: 2

    },
    {
      icon: faCode,
      text: "Code for Fun",
      id: 3
    },
    {
      icon: faPencilRuler,
      text: "Amateur Graphic Designer",
      id: 4
    },
    {
      icon: faGraduationCap,
      text: "Master in Chemical Engineering @ Carnegie Mellon",
      id: 5
    },
  ]

  const Sidebar = sidebarContent.map(data =>
    <div className="widgets-div mb-3 ms-4" key={data.id}>
      <div className="icon-div text-center">
        <FontAwesomeIcon icon={data.icon} />
      </div>
      <div className="text-div">
        <span>{data.text}</span>
      </div>
    </div>
  )

  return (
    <Container className="wrapper">
      <Row className="mt-4 d-flex justify-content-center">
        <Col md={4} xs={11} className="position-sticky ">
          <Col md={10}>
            <img alt="headphoto" className="head-image rounded mx-auto d-block" src={"me.png"} />
            <div className='text-center fw-medium pb-1'><big>Pengfei Cheng</big></div>
            <div className='text-center pb-3'>PhD candidate in Chemical and Biomolecular Engineering @ Georgia Tech</div>
            {Sidebar}
          </Col>
        </Col>
        <Col md={8} xs={11} className="mt-3 mt-md-0">
          <h3 className="fw-medium">Hello visitor!</h3>
          <h4>About me</h4>
          <p>
            I am currently a 4th-year PhD candidate at <a href="https://www.jkscottresearchgroup.com">Scott Lab</a> at Georgia Tech.
            All my research interests are around <span className="fw-medium">mathematical optimization</span>,
            some more application-based and some more theoretical.
            Working in this field as a student in chemical engineering
            provides some
            very interesting perspectives (and sometimes weird feelings) for me.
            I am interested in applying advanced optimization techniques and, probably
            more importantly, promoting their usage in industry after graduation.
          </p>
          <h4>Why there is a website with my name?</h4>
          <p>
            I am also interested in many other topics, such as data science, graphic
            design, and coding in general.
            This website was created initially for practising writing webapp in Flask,
            then I realized it was also a good opportunity to
          </p>
          <ul>
            <li>
              showcase interesting things I have created;
            </li>
            <li>
              collect information around certain topics that was initially not
              centralized, which I suffered from.
            </li>
          </ul>
          <h4>More about me</h4>
          <p>
            You can check my research experience on my <a href="CV.pdf">CV</a>, my
            professional
            experience on my <a href="https://www.linkedin.com/in/pengfei-cheng/">LinkedIn page</a>, and
            my posts on the <a href="/blog">Blog page</a>.
            You can always reach out to me via <a href="mailto:me@pengfeicheng.com">email</a>.
          </p>
          <p>
            Have fun.
          </p>
        </Col>
      </Row>
    </Container>
  )
}