import React, { useEffect, useRef, useState } from "react";
import { ListGroup, Row, Col, Button, Modal, Form } from "react-bootstrap";
import ChineseMeaningBlock from "../components/ChineseMeaningBlock";
import EnglishMeaningBlock from "../components/EnglishMeaningBlock";
import MyBreadcrumb from "../components/MyBreadcrumb";
import Spinner from "../components/Spinner";
import SelectWordList from "../components/SelectWordList";
import AutoDismissAlert from "../components/AutoDismissAlert";
import useLookUpMeaning from "../utils/useLookUpMeaning";
import useDocumentTitle from "../utils/useDocumentTitle";

export default function BulkImportWords() {

  useDocumentTitle("Bulk Import");

  // word list (file name)
  const [wordListFile, setWordListFile] = useState('default');
  // // word list (name)
  // const [wordListName, setWordListName] = useState('Default List');
  // all the word lists
  const [lists, setLists] = useState([]);

  // chosen word for showing meanings in modal
  const [word, setWord] = useState('');

  // look up meaning
  const [loading, ChineseMeaning, EnglishMeaning, ChineseMeaningLoaded, EnglishMeaningLoaded, lookUpMeaning] = useLookUpMeaning();

  // show DOM elements
  const [showAlert, setShowAlert] = useState(false);
  const [showNewWords, setShowNewWords] = useState(false);
  const [showWordMeaningModal, setShowWordMeaningModal] = useState(false);
  const [showAddToListModal, setShowAddToListModal] = useState(false);

  // fetch success and message
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [message, setMessage] = useState('');

  // new words from bulk import
  const [newWords, setNewWords] = useState([]);
  // chosen new words
  const [chosenNewWords, setChosenNewWords] = useState([]);
  // existing words from bulk import
  const [existingWords, setExistingWords] = useState([]);

  // full input text
  const [fullText, setFullText] = useState('');

  // textarea
  const textareaRef = useRef(null);

  // auto focus
  useEffect(() => {
    textareaRef.current.focus()
  }, [])

  // get word lists
  useEffect(() => {
    fetch("/api/vocabulary/lists.json")
      .then(response => response.json())
      .then(data => {
        setLists(data);
      })
  }, [])

  const getWordListName = (fileName) => {
    return lists.filter(l => l.fileName === fileName)[0].name;
  }

  // update meaning every time word is updated
  useEffect(() => {
    lookUpMeaning(word)
  }, [word, lookUpMeaning])

  function handleFindNewWords() {
    fetch("/api/vocabulary/receive-long-string", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(fullText)
    })
      .then(response => {
        setFetchSuccess(response.ok);
        if (response.ok) {
          response.json().then(
            d => {
              setNewWords(d.results.filter(r => r.list === '_new')[0].words);
              setExistingWords(d.results
                .filter(r => r.list !== '_new')
                .filter(r => r.list !== 'common_words')
                .filter(r => r.list !== 'nonwords'));
            }
          )
        } else {
          response.json().then(
            d => {
              setMessage(d.message);
              setShowAlert(true);
            }
          )
        }
        setShowNewWords(true);
      })
  }

  function updateChosenWords() {
    setChosenNewWords([...document.querySelectorAll('.check-word')].filter(el => el.checked === true).map(el => el.id.split('check-')[1]));
  }

  function handleAddWords(wordListFile, showAlert = true) {
    fetch("/api/vocabulary/add-words", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        words: chosenNewWords,
        fileName: wordListFile
      })
    })
      .then(response => {
        setFetchSuccess(response.ok);
        return response.json();
      })
      .then(d => setMessage(d.message))
      .then(setShowAddToListModal(false))
      .then(handleFindNewWords())
      .then(setShowAlert(showAlert))
  }

  const Buttons = <Row className="mt-2 mb-2">
    <Col md={2}>
      <Button size="sm" variant="GT-outline-click" className="w-100"
        onClick={() => document.querySelectorAll('.check-word').forEach(el => el.checked = true)}>
        select all
      </Button>
    </Col>
    <Col md={2}>
      <Button size="sm" variant="GT-outline-click" className="w-100"
        onClick={() => document.querySelectorAll('.check-word').forEach(el => el.checked = false)}>
        deselect all
      </Button>
    </Col>
    <Col md={2}>
      <Button size="sm" variant="GT-click" className="w-100" onClick={() => {
        updateChosenWords();
        setShowAddToListModal(true);
      }}>
        add to list
      </Button>
    </Col>
    <Col md={3}>
      <Button size="sm" variant="GT-click" className="w-100" onClick={() => {
        handleAddWords('common_words', false)
      }}>
        mark as common words
      </Button>
    </Col>
    <Col md={3}>
      <Button size="sm" variant="GT-click" className="w-100" onClick={() => {
        handleAddWords('nonwords', false)
      }}>
        mark as non-words
      </Button>
    </Col>
  </Row>;

  const NewWordsBlock =
    showNewWords && <div className="mt-3">
      <div className="gold-border-bottom mb-4">
        <h4>New words</h4>
        <Row className="mt-2">
          {newWords.length > 0
            ?
            <>
              {Buttons}
              {newWords.map(w =>
                <Col md={4} key={w} className="mb-1">
                  <input type="checkbox" className="me-2 check-word" id={"check-" + w} onClick={updateChosenWords} />
                  <button className="word button-like-a" id={w} onClick={(e) => {
                    setWord(w);
                    setShowWordMeaningModal(true);
                  }
                  }>{w}</button>
                </Col>
              )}
              {Buttons}
            </>
            :
            <>
              <p>No new words.</p>
            </>
          }
        </Row>
      </div>
    </div>

  const ExistingWordsBlock =
    showNewWords && <div className="mt-3">
      <div className="gold-border-bottom mb-4">
        <h4>Existing words</h4>
        {existingWords.map(wl => wl.words.length).reduce((a, b) => a + b, 0) > 0
          ? existingWords.map(wl =>
            wl.words.length > 0 &&
            <Row key={wl.list} className="mb-2">
              <h5>{getWordListName(wl.list)}</h5>
              {wl.words.map(w =>
                <Col md={4} key={w} className="mb-1">
                  <button className="word button-like-a" href="#" id={w} onClick={(e) => {
                    setWord(w);
                    setShowWordMeaningModal(true);
                  }
                  }>{w}</button>
                </Col>
              )}
            </Row>
          )
          : <p>No existing words.</p>
        }
      </div>
    </div>

  return (
    <>
      <MyBreadcrumb link={window.location.pathname} />
      <AutoDismissAlert success={fetchSuccess} show={showAlert} setShow={setShowAlert}>
        {message}
      </AutoDismissAlert>
      <h3 className="fw-medium mb-3 mt-1" id="col-title">Bulk import words to list</h3>
      <ListGroup>
        <ListGroup.Item>
          <Form onSubmit={(e) => {
            e.preventDefault();
            handleFindNewWords();
          }
          }>
            <Row>
              <Form.Group className="mb-3" controlId="fullText">
                <Form.Label>full text</Form.Label>
                <Form.Control as="textarea" ref={textareaRef} rows={3} onChange={(e) => setFullText(e.target.value)} />
              </Form.Group>
            </Row>
            <Row>
              <Col md={3}>
                <Button size="sm" className="w-100" type="submit" variant="GT-outline-click">find new words</Button>
              </Col>
            </Row>
          </Form>
        </ListGroup.Item>
      </ListGroup>
      {NewWordsBlock}
      {ExistingWordsBlock}
      {/* show word meaning */}
      <Modal show={showWordMeaningModal} onHide={() => setShowWordMeaningModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{word}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EnglishMeaningBlock word={word} meanings={EnglishMeaning} loaded={EnglishMeaningLoaded} />
          <ChineseMeaningBlock word={word} meanings={ChineseMeaning} loaded={ChineseMeaningLoaded} />
          <Spinner loading={loading} />
        </Modal.Body>
      </Modal>
      {/* add to list */}
      <Modal show={showAddToListModal} onHide={() => setShowAddToListModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add to word list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Choose a word list to add {chosenNewWords.length > 1 ? "words" : "word"}:
          <SelectWordList list={wordListFile} lists={lists} size="sm"
            onChange={e => {
              setWordListFile(e.target.value)
              // setWordListName(e.target.options[e.target.selectedIndex].text)
            }} />
          The following {chosenNewWords.length > 1 ? "words" : "word"} will be added:
          <ul>
            {chosenNewWords.map(w => <li key={w}>{w}</li>)}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowAddToListModal(false)}>
            cancel
          </Button>
          <Button variant="primary" onClick={() => handleAddWords(wordListFile)}>
            confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}