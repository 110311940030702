import React, { useEffect, useState } from "react";
import MyBreadcrumb from "../components/MyBreadcrumb";
import * as Showdown from "showdown";
import { Pagination } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Spinner from "../components/Spinner";
import useDocumentTitle from "../utils/useDocumentTitle";

const showdownHighlight = require("showdown-highlight");
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
  extensions: [showdownHighlight({
    pre: true
  })]
});

export default function Blog() {

  useDocumentTitle("Blog");

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);

  let { page } = useParams();

  useEffect(() => {

    fetch('/api/blog?page=' + page, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setPosts(data.posts);
        setTotal(data.total);
        setPages(data.pages);
        setLoading(false);
      })
      .catch(error => console.error(error))
  }, [page])

  const postDivs = posts.map(post =>
    <article className="media content-section" key={post.id}>
      <div className="w-100 p-3">
        <h2>
          <a href={"/blog/post/" + post.id}>{post.title}</a>
        </h2>
        <div className="article-metadata">
          <div className="text-muted">{post.datePosted}</div>
        </div>
        <p className="article-content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.content) }} />
      </div>
    </article>
  )

  let items = [];
  let active = !page ? 1 : page;
  for (let number = 1; number <= pages; number++) {
    items.push(
      <Pagination.Item key={number} active={number === Number(active)} href={"/blog/" + number}>
        {number}
      </Pagination.Item>
    );
  }


  return (
    <>
      <MyBreadcrumb link={window.location.pathname} />
      <h3 className="fw-medium mb-3 mt-1" id="col-title">All posts {!loading ? `(${total})` : null}</h3>
      <Spinner loading={loading} />
      {postDivs}
      {!loading
        ?
        <div className="d-flex justify-content-center">
          <Pagination>{items}</Pagination>
        </div>
        : null}
    </>
  );
}