import React, { useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { login, useAuth, logout } from "../auth";
import AutoDismissAlert from "../components/AutoDismissAlert";
import MyBreadcrumb from "../components/MyBreadcrumb";
import useDocumentTitle from "../utils/useDocumentTitle";

export default function Login() {

  useDocumentTitle("Login");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [logged] = useAuth();
  const [failMessage, setFailMessage] = useState('');
  const [showFailAlert, setShowFailAlert] = useState(false);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    fetch(`/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: email,
        password: password,
        remember: remember,
      })
    })
      .then(response => {
        !response.ok && setShowFailAlert(true);
        return response.json();
      })
      .then(token => {
        if (token.access_token) {
          login(token);
          window.location.pathname = "/";
        }
        else {
          setFailMessage(token.message);
        }
      })
  }

  const logInForm = <Form onSubmit={e => handleSubmit(e)}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Username</Form.Label>
      <Form.Control type="text" placeholder="" onChange={e => setEmail(e.target.value)} />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicPassword">
      <Form.Label>Password</Form.Label>
      <Form.Control type="password" placeholder="" onChange={e => setPassword(e.target.value)} />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formBasicCheckbox">
      <Form.Check type="checkbox" label="remember me" onChange={e => setRemember(e.target.checked)} />
    </Form.Group>
    <Button variant="GT" type="submit">login</Button>
  </Form>;

  const logOutForm =
    <>
      <p>
        You are already logged in.
      </p>
      <Button variant="GT" onClick={logout}>log out</Button>
    </>;

  return (
    <Row className="d-flex justify-content-center justify-content-md-start">
      <Col xs={10} md={8}>
        <MyBreadcrumb link={window.location.pathname} />
        <h3 className="fw-medium mb-3" id="col-title">Login</h3>
        <AutoDismissAlert success={false} show={showFailAlert} setShow={setShowFailAlert}>
          {failMessage}
        </AutoDismissAlert>
        <div className="content-section">
          {!logged ? logInForm : logOutForm}
        </div>
      </Col>
    </Row>
  );
}