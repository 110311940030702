import React from "react";

const abbrPartOfSpeech = {
  noun: "n.",
  verb: "v.",
  adjective: "adj.",
  adverb: "adv.",
  preposition: "prep.",
  abbreviation: "abbr."
}

function get(object: any, key: string, default_value: string) {
  var result = object[key];
  return (typeof result !== "undefined") ? result : default_value;
}

interface EnglishMeaning {
  meta: meta,
  hom: number,
  hwi: {
    hw: string,
    prs?: pr[],
  },
  shortdef: string[],
  fl: string
}

interface pr {
  mw: string,
  sound: {
    audio: string,
    ready: string,
    stat: string
  }
}

interface meta {
  id: string,
  // uuid: string,
  // sort: string,
  // "user_comments": "no",
  // "src": "collegiate",
  // "section": "alpha",
  // "stems": ["fuck", "fucked", "fucking", "fucks"],
  // "offensive": true
}

function parseEnglishJSON(meanings: EnglishMeaning[], word: string) {
  if (typeof meanings[0] === "object") {
    return meanings
      .filter(m => m.meta.id.toLowerCase().includes(word.toLowerCase()))
      .filter(m => m.meta.id.split(':')[0].toLowerCase() === word.toLowerCase())
      .map(m => {
        let phonetic;
        if (m.hwi.prs) {
          phonetic = m.hwi.prs.map(mm => mm.mw).join('; ')
        } else {
          phonetic = '';
        }
        return {
          foundWord: m.meta.id.split(':')[0],
          phonetic: phonetic,
          meaning: m.shortdef.join('; '),
          label: m.fl
        };
      })
  } else {
    return [];
  }
}

export default function EnglishMeaningBlock({ loaded, meanings, word }: {
  loaded: boolean,
  meanings: EnglishMeaning[],
  word: string
}) {

  const parsedMeanings = parseEnglishJSON(meanings, word);

  return (
    loaded
      ? (parsedMeanings.length > 0
        ? <>
          {parsedMeanings.map(m =>
            <div key={m.meaning}>
              {m.foundWord !== word
                ? <small>(found word: {m.foundWord})</small>
                : null
              }
              {m.phonetic.length > 0
                ? <p>/{m.phonetic}/</p>
                : null}
              <p>{get(abbrPartOfSpeech, m.label, m.label)}, {m.meaning}</p>
            </div>
          )}
        </>
        : <p>English meaning not found</p>
      )
      : null
  )
}