import React from "react";
import { Form } from "react-bootstrap";
import { WordList } from "../interfaces";

export default function SelectWordList({ list, lists, ...rest }: {
  list: string,
  lists: WordList[]
}) {

  return (
    typeof lists !== "undefined" &&
    <Form.Select {...rest} value={list}>
      {lists.map(l =>
        <option key={l.fileName} value={l.fileName}>
          {l.name}
        </option>)}
    </Form.Select>
  )
}