import React from "react";
import { Outlet, To } from "react-router-dom";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faBookOpen, faBook, faProjectDiagram } from "@fortawesome/free-solid-svg-icons"
import { faNewspaper, faCheckSquare, IconDefinition } from "@fortawesome/free-regular-svg-icons"
import MyNavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import { useAuth } from "../auth";

interface Props {
  href: To,
  text: string,
  icon: IconDefinition,
  requireLoggedIn?: boolean,
  disabled?: boolean
}

function QuickLinkItem(props: Props) {

  const [logged] = useAuth();

  return (
    <ListGroup.Item as={NavLink} key={props.text}
      action to={props.href} className="list-group-QL-item"
      disabled={(props.requireLoggedIn && !logged) || props.disabled}>
      <Row className="d-flex justify-content-center">
        <Col xs={9} md={10}>
          <div className="icon-div ms-4">
            <FontAwesomeIcon icon={props.icon} />
          </div>
          <div className="text-div">
            <span>{props.text}</span>
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

const quickLinkItems = [
  {
    href: "/",
    text: "Homepage",
    icon: faHome,
    disabled: false,
  },
  {
    href: "/blog",
    text: "Newest Posts",
    icon: faNewspaper,
  },
  {
    href: "/dictionary",
    text: "Dictionary",
    icon: faBookOpen,
  },
  {
    href: "/vocabulary",
    text: "Vocabulary",
    icon: faBook,
    requireLoggedIn: true
  },
  {
    href: "/todo",
    text: "Todo",
    icon: faCheckSquare,
    requireLoggedIn: true
  },
  {
    href: "/projects",
    text: "My Projects",
    icon: faProjectDiagram,
    disabled: true,
  }
  // {
  //   href: "mailto:me@pengfeicheng.com",
  //   text: "Email Me",
  //   icon: faEnvelope,
  //   disabled: false,
  // }
]

export default function LayoutQuickLinks() {
  return (
    <>
      <MyNavBar />
      <Container>
        <Row className="mt-4 h-100 wrapper">
          <Col md={4} className="sidebar mt-2">
            <h3 className="text-center fw-medium">Quick links</h3>
            <Row className="d-flex justify-content-center">
              <Col xs={10} md={8}>
                <ListGroup className="mt-3">
                  {quickLinkItems.map(item => QuickLinkItem(item))}
                </ListGroup>
              </Col>
            </Row>
          </Col>
          <Col md={8} className="mt-2">
            <Outlet />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}