import React from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { useAuth, logout } from "../auth";

export default function MyNavBar() {
    const [logged] = useAuth();
    return (
        <Navbar sticky="top" bg="GTgold" variant="dark" expand="lg" className="py-3">
            <Container>
                <Navbar.Brand href="/" className="fw-medium">
                    <img
                        alt=""
                        src="/favicon.ico"
                        width="40"
                        height="40"
                        className="d-inline-block align-center me-2 rounded d-none d-lg-inline"
                    />{' '}
                    Pengfei Cheng's Homepage
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to="/">Home</Nav.Link>
                        <Nav.Link as={NavLink} to="/blog">Blog</Nav.Link>
                        <Nav.Link as={NavLink} to="/dictionary">Dictionary</Nav.Link>
                        <Nav.Link as={NavLink} to='/CV.pdf' target="_blank">CV</Nav.Link>
                    </Nav>
                    {logged &&
                        <Nav>
                            <Nav.Link as={NavLink} to="/vocabulary">Vocabulary</Nav.Link>
                            <Nav.Link as={NavLink} to="/todo">Todo</Nav.Link>
                            <Nav.Link as={NavLink} to="/blog/new-post">New Post</Nav.Link>
                            <Nav.Link onClick={logout}>Logout</Nav.Link>
                            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                        </Nav>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>);
}
