import React from "react";

interface props {
  loading: boolean,
}

export default function Spinner(props: props) {

  return (
    props.loading 
      ?
      <div className="d-flex justify-content-center">
        <div className="lds-spinner mb-4">
          {Array.from(Array(12).keys()).map( n => <div key={n}></div>)}
        </div>
      </div>
      : null
  )
}