export function parsePythonTime(t: String): Date | null {
  if (t === "None") {
    return null;
  }
  const dateString = t.split(' ')[0];
  const timeString = t.split(' ')[1];

  return new Date(dateString + 'T' + timeString + 'Z');
}

export function convertDateToString(date: Date): String {

  if (!date) {
    return "";
  }

  let dateString = date.toLocaleDateString();

  // remove year if it is the same year
  const year = String(new Date().getFullYear());
  if (year === dateString.slice(-4)) {
    dateString = dateString.slice(0, -5);
  }

  return dateString;
}

export function isOverdue(t: Date): Boolean {

  if (!t) {
    return false;
  }

  const today = new Date();
  const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  return t < todayDate;

}

export function isClose(t: Date): Boolean {

  if (!t) {
    return false;
  }

  const today = new Date();
  const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  return Math.floor((t.getTime() - todayDate.getTime()) / (1000 * 60 * 60 * 24)) <= 1 && t >= todayDate;

}