import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";

export default function AutoDismissAlert({ success, show, setShow, children, ...props }: {
  success: boolean,
  show: boolean,
  setShow: Function,
  children: JSX.Element | string
}) {

  useEffect(() => {
    if (show === true) {
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [show, setShow])

  return (
    <Alert {...props} variant={success ? "success" : "danger"} show={show}>
      {children}
    </Alert>)
}