import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import Todo from './routes/Todo';
import Home from "./routes/Home";
import Layout from "./routes/Layout";
import LayoutQuickLinks from "./routes/LayoutQuickLinks";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import NoPage404 from './routes/404';
import Blog from './routes/Blog';
import SinglePost from './routes/SinglePost';
import { useAuth } from './auth';
import Login from './routes/Login';
import Dictionary from './routes/Dictionary';
import Vocabulary from './routes/Vocabulary';
import BulkImportWords from './routes/BulkImportWords';
import NewPost from './routes/NewPost';
import UpdatePost from './routes/UpdatePost';
import Playground from './routes/Playground';
import 'bootstrap/dist/css/bootstrap.min.css';  // Importing the Bootstrap CSS


const AllRoutes = () => {


  const [logged] = useAuth();

  type Props = {
    children: JSX.Element,
  };

  function RequireAuth({ children }: Props) {
    let location = useLocation();

    if (!logged) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />} >
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
        </Route>
        <Route element={<LayoutQuickLinks />}>
          <Route path="login" element={<Login />} />
          <Route path="playground" element={<Playground />} />
          <Route path="todo" element={<Todo />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:page" element={<Blog />} />
          <Route path="blog/new-post" element={
            <RequireAuth>
              <NewPost />
            </RequireAuth>} />
          <Route path="blog/post/:postId" element={<SinglePost />} />
          <Route path="blog/post/:postId/update-post" element={
            <RequireAuth>
              <UpdatePost />
            </RequireAuth>} />
          <Route path="dictionary" element={<Dictionary />} />
          <Route path="vocabulary" element={
            <RequireAuth>
              <Vocabulary />
            </RequireAuth>} />
          <Route path="vocabulary/bulk-import-words" element={
            <RequireAuth>
              <BulkImportWords />
            </RequireAuth>} />
          <Route path="*" element={<NoPage404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

ReactDOM.render(<AllRoutes />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
