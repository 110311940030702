import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Project } from "../interfaces";

function NewTaskForm(props: {
  projectId: number,
  update: Function,
  setShowAlert: Function,
  setFetchSuccess: Function,
  setMessage: Function,
  projects: Project[],
  setProjectId: Function
}) {
  const [name, setName] = useState("");
  const [dueDate, setDueDate] = useState<Date | null>(null);

  function handleSubmit(e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent) {
    e.preventDefault();
    if (name.length > 0 && props.projectId !== 0) {
      // reset input
      setName("");
      setDueDate(null);

      // console.log("adding new task...")
      fetch("/api/new-task", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: name,
          projectId: props.projectId,
          dueDate: dueDate
        })
      })
        .then(response => {
          if (!response.ok) {
            console.error(response);
          } 

          // this avoids updating before fetch call is done
          setTimeout(
            props.update
          , 100)
        })
    } else {
      if (props.projectId === 0) {
        props.setShowAlert(true);
        props.setFetchSuccess(false);
        props.setMessage("Please choose a project.");
      } else {
        props.setShowAlert(true);
        props.setFetchSuccess(false);
        props.setMessage("Please enter task name.");
      }
    }
  }
  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <Row className="d-flex align-items-end mb-2">
        {/* input name */}
        <Col md={5} xs={12} className="mb-2">
          <Form.Label><small>new task</small></Form.Label>
          <Form.Control
            type="text"
            name="text"
            id="new-todo-input"
            size="sm"
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />
        </Col>
        {/* project */}
        <Col md={3} xs={5} className="mb-2">
          <Form.Label><small>project</small></Form.Label>
          <Form.Select size="sm" value={props.projectId} onChange={(e) => props.setProjectId(e.target.value)}>
            <option value={0}></option>
            {props.projects.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
          </Form.Select>
        </Col>
        {/* due date */}
        <Col md={2} xs={4} className="mb-2">
          <Form.Label><small>due date</small></Form.Label>
          <DatePicker
            selected={dueDate}
            // onSelect={}
            onChange={date => setDueDate(date)}
          />
        </Col>
        {/* confirm */}
        <Col md={2} xs={3} className="mb-2">
          <Button size="sm" variant="GT-click" type="submit" className="w-100">add</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default NewTaskForm;
